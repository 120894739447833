import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  About,
  DaySmartAppointmentBooking,
} from "@bluefin/components";
import { Grid, Divider } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class BookNowPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout hero={false} className={"book-now-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              componentIdentifier: "background",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={""}
                header={"Book an Appointment Online"}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
              <div className={"custom-copy-container"}>
                <span>
                  Please use our online booking platform below for requesting an
                  appointment with us. Please note that this is a request only
                  and does not confirm an appointment at this time. Our team
                  will review your request and get back to you as soon as
                  possible to confirm availability. If you need immediate
                  assistance, please reach out to us at&nbsp;
                </span>
                <a
                  href={"tel:+18502342100"}
                  target={"_blank"}
                  className={"phone-link"}
                >
                  (850) 234-2100
                </a>
                <span>&nbsp;We look forward to seeing you soon!</span>
              </div>
              <Divider hidden={true} />
              <DaySmartAppointmentBooking
                guid={"48ee58b4-cef6-4f75-bdaf-c4efd2866a6d"}
                title={"Book Appointment Now"}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Book Now" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      seoTitle
    }
  }
`;
